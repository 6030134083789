import { useRef, useState } from "react";
import { RadioGroup, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { productsCollection } from "../libs/firestore";
import _ from "lodash";
import { addDoc } from "@firebase/firestore";

export default function AddItem({ zones }) {
  const [open, setOpen] = useState(false);
  const [selectedZone, setZone] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemQt, setItemQt] = useState("");
  const inputRef = useRef(null);

  const addNewItem = () => {
    if (!selectedZone || itemName === "") {
      return "";
    }

    addDoc(productsCollection, {
      title: itemName,
      quantity: itemQt,
      zone: selectedZone,
    });

    setZone("");
    setItemName("");
    setItemQt("");

    return inputRef.current.focus();
  };
  return (
    <>
      <Transition
        className="fixed inset-x-0 bottom-0 z-50"
        show={open}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
        afterEnter={() => inputRef.current.focus()}
      >
        <div className="px-3 py-8 bg-gray-100 rounded-t-xl">
          <div className="flex space-x-2">
            <input
              className="flex-1 px-3 py-2 border border-blue-800 rounded-lg"
              type="text"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              ref={inputRef}
              placeholder="Ajouter un produit"
            />
            <input
              className="w-16 px-3 py-2 text-center border border-blue-800 rounded-lg"
              type="text"
              placeholder="Qt."
              value={itemQt}
              onChange={(e) => setItemQt(e.target.value)}
            />
          </div>
          <div className="">
            <RadioGroup
              className="flex flex-wrap justify-center mt-4"
              value={selectedZone}
              onChange={setZone}
            >
              {_.sortBy(zones, [(o) => o.position]).map((zone) => (
                <RadioGroup.Option
                  key={zone.id}
                  value={zone.id}
                >
                  {({ checked }) => (
                    <span
                      className={`cursor-pointer rounded-full px-2 py-1 inline-flex items-center ${
                        checked ? "bg-blue-800 text-white" : ""
                      }`}
                    >
                      <div
                        className={`rounded-full w-2 h-2 border-2 border-blue-800 mr-2 ring-2 ring-blue-200 ${
                          checked && "bg-blue-200"
                        }`}
                      ></div>
                      <div className="text-xs uppercase">{zone.name}</div>
                    </span>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <button
              type="button"
              className="block w-full px-4 py-4 mt-5 font-medium text-white uppercase bg-blue-800 rounded-full"
              onClick={() => addNewItem()}
            >
              Ajouter
            </button>
          </div>
        </div>
        <button
          onClick={() => setOpen(false)}
          className="absolute bottom-full right-4"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="w-10 h-10 mb-2 text-white" aria-hidden="true" />
        </button>
      </Transition>
      <button
        onClick={() => setOpen(true)}
        className="fixed z-40 text-pink-400 right-3 bottom-4"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-20 h-20"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </>
  );
}
