import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

// Initialize Firebase
initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
});

export const firestore = getFirestore();
export const currentEntity = process.env.REACT_APP_ENTITY;

export const productsCollection = collection(firestore, `entities/${currentEntity}/products`);
export const zonesCollection = collection(firestore, `entities/${currentEntity}/zones`);
