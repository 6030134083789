import { useEffect, useState } from "react";
import _ from "lodash";
import AddItem from "./components/AddItem";
import Header from "./components/Header";
import { productsCollection, zonesCollection } from "./libs/firestore";
import Item from "./components/Item";
import { onSnapshot } from "firebase/firestore"; 

function App() {
  const [zones, setZones] = useState([]);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    onSnapshot(zonesCollection, (snap) => {
      const items = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setZones(items);
    })

    onSnapshot(productsCollection, (snap) => {
      const items = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProducts(items);
    })
  }, []);

  return (
    <div className="min-h-screen px-3 pt-10 pb-20 bg-blue">
      <AddItem zones={zones} />
      <div className="mb-8">
        <Header />
      </div>

      <div className="space-y-4">
        {_.sortBy(zones, [(o) => o.position])
          .filter(
            (r) =>
              products.filter((r0) => r0.zone === r.id && r0.check !== true)
                .length > 0
          )
          .map((zone) => (
            <div key={zone.id}>
              <div className="sticky top-0 py-2 text-sm text-blue-200 uppercase rounded-b-lg bg-blue">
                {zone.name}
              </div>

              <div className="space-y-2">
                {products
                  .filter((r) => r.zone === zone.id && r.check !== true)
                  .map((product) => (
                    <Item key={product.id} {...product} />
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default App;
